import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../../layouts/ru/sidebar"

import Sidebar from "~components/Sidebar"
import CardDeck from "~components/CardDeck"
import ContactUs from "~components/ContactUs"
import PriceCard, { PriceCardList } from "~components/PriceCard"
import LazyIframe from "~components/LazyIframe"
import ServiceHero from "~components/ServiceHero"
import ServiceIcon from "~components/ServiceIcon"
import CardDeckItem from "~components/CardDeckItem"
import ServiceSection from "~components/ServiceSection"
import ReviewCarousel from "~components/ReviewCarousel"
import ServiceIconList from "~components/ServiceIconList"
import StyledAccordion from "~components/StyledAccordion"
import StyledAccordionItem from "~components/StyledAccordionItem"

import MaleIcon from "~images/service-icons/male.svg"
import FileIcon from "~images/service-icons/file.svg"
import LaptopIcon from "~images/service-icons/laptop.svg"

import VCFAIcon from "~images/service-icons/voice-casting-foreign-actors.svg"
import VCLAIcon from "~images/service-icons/voice-casting-local-actors.svg"

import mailTo from "~utils/mailTo"
import { seoDefaultData } from "~utils/seo"

const VoiceCasting = ({ data, location }) => {
  const reviews = data.reviews.nodes
  const sidebarImage = data.sidebarImage.childImageSharp.fluid
  const contactEmail = data.site.siteMetadata.adminEmail
  const { seo } = data.seoPagesData ?? { seo: seoDefaultData }

  return (
    <Layout
      Sidebar={
        <Sidebar
          title="Голосовое озвучивание"
          text="Глазами не увидишь то, что может нарисовать нам речь."
          autor="Китайская пословица"
          image={sidebarImage}
        />
      }
      ServiceHero={
        <ServiceHero>
          <h3>
            Нам нравится думать о человеческом голосе как о самом совершенном
            музыкальном инструменте.
          </h3>
          <p>
            Мы не знаем, что ещё способно настолько точно раскрыть посыл автора,
            передать весь спектр эмоций и неуловимые нюансы настроения. И если
            вокал — это один из значимых элементов музыки, то голосовое
            озвучивание — это самостоятельный вид искусства.
          </p>
          <p>
            Правильное голосовое озвучивание или озву́чение — это не просто
            начитка нужного текста хорошо поставленным голосом. Это скорее про
            управление вниманием слушателя, раскрытие персонажей и создание
            атмосферы. Это то, что заставляет человека слушать и верить
            услышанному.
          </p>
        </ServiceHero>
      }
      location={location}
      title={seo.title}
      disableSiteNameInTitle
      description={seo.description}
    >
      <ServiceSection title="Что мы делаем?">
        <p>
          Если говорить коротко — мы продюсируем голосовое озвучивание, а
          конкретно занимаемся:
        </p>

        <ServiceIconList align="space-evenly">
          <ServiceIcon
            title="Кастингом актёров голосового озвучивания"
            icon={MaleIcon}
            style={{ maxWidth: 200 }}
          />
          <ServiceIcon
            title="Организацией процесса записи и ведением проекта"
            icon={LaptopIcon}
            style={{ maxWidth: 200 }}
          />
          <ServiceIcon
            title="Обработкой аудио файлов"
            icon={FileIcon}
            style={{ maxWidth: 200 }}
          />
        </ServiceIconList>
        <p>
          А ещё мы сотрудничаем с профессиональными вокалистами, чтобы добавить
          необходимую человечность и глубину{" "}
          <Link to="/ru/services/audio-branding">аудио брендам</Link> и{" "}
          <Link to="/ru/services/music-composing">музыке</Link>.
        </p>
      </ServiceSection>
      <ServiceSection title="База голосов">
        <p>
          За годы работы мы сформировали базу актёров голосового озвучивания и
          вокалистов. Каждый из них — носитель самых разных языков и акцентов,
          тембров и интонаций. В этом вы можете убедиться сами, плейлисты с
          примерами голосов актёров и вокалистов вы найдёте ниже.
        </p>

        <h3>Актёры озвучивания</h3>
        <LazyIframe
          title="Актёры озвучивания"
          width="100%"
          height="450"
          scrolling="no"
          frameborder="no"
          allow="autoplay"
          src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/303617614%3Fsecret_token%3Ds-jgwUQD8Wr0W&color=%23f23b0d&auto_play=false&hide_related=false&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"
        />
        <p>
          Благодаря тому, что все актёры из нашей базы — носители языков, услуга{" "}
          <strong>пруфридинга</strong> уже включена в стоимость работ.
        </p>

        <h3>Вокалисты</h3>
        <LazyIframe
          title="Вокалисты"
          width="100%"
          height="450"
          scrolling="no"
          frameBorder="no"
          allow="autoplay"
          src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1259357083%3Fsecret_token%3Ds-vWRXTht4Qep&color=%23f23b0d&auto_play=false&hide_related=false&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"
        />
      </ServiceSection>
      <ServiceSection title="Как проходит процесс?">
        <p>
          За последние несколько лет мы выработали понятную и эффективную схему
          сотрудничества. С одной стороны, мы следим, чтобы на каждом этапе у
          вас было чёткое понимание происходящего и полный контроль над ним. А с
          другой — собираем максимум информации о вашем проекте, чтобы
          обеспечить его лучшими голосами.
        </p>
        <StyledAccordion>
          <StyledAccordionItem title="Знакомство">
            <p>
              Вы заполняете бриф и предоставляете сценарий, который нам нужно
              будет озвучить. Мы назначаем аудио- или видеоконференцию в Google
              Meet или Zoom, на которой знакомимся друг с другом, обсуждаем
              проект и понимаем, насколько готовы сотрудничать. Для нас важно
              совпадать с клиентом в общем видении и принципах работы.
            </p>
            <p>
              Если вы не можете делиться информацией о проекте без NDA, то в
              самом начале общения мы подписываем необходимые документы.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Препродакшн">
            <p>
              На этом этапе мы детально изучаем техническое задание, сценарий и
              описание персонажей, подбираем подходящих исполнителей и
              демонстрируем их вам. При необходимости — записываем пробные
              фразы, чтобы вам было проще определиться с актёром.
            </p>
            <p>
              Параллельно вместе с юристами мы готовим договор сотрудничества.
              Далее мы обсуждаем и согласовываем всё с вами и приступаем к
              следующему этапу.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Юридические формальности">
            <p>
              Мы с вами подписываем договор, вы высылаете полную предоплату за
              проект, а мы в свою очередь подписываем договор с актёром и
              передаём ему сценарий.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Продакшн">
            <p>
              Актёр озвучивания отправляет нам готовую запись, мы проверяем её
              на соответствие требованиям и отправляем вам. Если вам покажется,
              что исполнитель недостаточно корректно передал какие-то интонации
              или общий посыл, вы сможете бесплатно внести до двух правок на
              каждую строку сценария.
            </p>
            <p>
              После того, как вас устроит работа актёра, мы займёмся стандартной
              обработкой аудиофайла. На этом же этапе мы можем дополнительно
              обработать записанный голос, добавить нужных эффектов.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Приём проекта">
            <p>
              После всех согласований мы высылаем вам конечные файлы. Этот
              момент — последний шанс воспользоваться бесплатными правками.
            </p>
            <p>
              {" "}
              Вы имплементируете записанные голоса в свой проект и даёте нам к
              нему доступ, чтобы мы могли убедиться, что всё звучит правильно. В
              случае, когда что-то пошло не по плану, мы составляем список
              исправлений и следим, чтобы вы смогли всё настроить.{" "}
            </p>
            <p>
              Когда всё готово и хорошо звучит, мы с вами подписываем акт
              приёма-передачи.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Поддержка">
            <p>
              Мы любим следить за развитием наших партнёров и клиентов. Часто
              после приёма проекта работа только начинается и мы всегда готовы
              продолжить сотрудничество.
            </p>
          </StyledAccordionItem>
        </StyledAccordion>
      </ServiceSection>

      <ServiceSection title="Стоимость">
        <p style={{ marginBottom: 60 }}>
          Стоимость проекта зависит от выбора актёра озвучивания, базовая
          обработка голоса (чистка, эквализация, компрессия, лимитирование)
          включена в стоимость, специфическая обработка (эффект радио, эхо,
          голос робота и т.д.) оговаривается отдельно.
        </p>

        <PriceCardList>
          <PriceCard
            icon={VCLAIcon}
            title="Локальные актёры озвучивания"
            subTitle="Доступные языки:"
            list={["украинский", "русский", "английский"]}
            price="$400/час"
          />
          <PriceCard
            icon={VCFAIcon}
            title="Иностранные актёры озвучивания"
            subTitle="Доступные языки:"
            list={[
              "английский",
              "немецкий",
              "французский",
              "испанский",
              "китайский",
            ]}
            price="$600/час"
          />
        </PriceCardList>

        <p>
          Час работы включает в себя запись актёра и базовую обработку голоса. В
          среднем за один час работы можно озвучить 800-1000 слов сценария, но
          всё зависит от специфики проекта.
        </p>
        <p>Минимальная сумма заказа составляет стоимость одного часа работы.</p>
        <p>
          Если в списке доступных языков вы не нашли нужный вам — обязательно{" "}
          <a
            rel="nofollow noopener noreferrer"
            target="_blank"
            href={`mailto:${contactEmail}`}
          >
            напишите нам
          </a>{" "}
          и мы подберём нужного актёра.
        </p>
        <p>
          Стоимость работы вокалистов напрямую зависит от объёма и сложности
          проекта и рассчитывается индивидуально.
        </p>
      </ServiceSection>
      <ServiceSection title="Наш подход">
        <CardDeck>
          <CardDeckItem variant="rect" textAlign="left">
            В нашем понимании идеальная работа над голосовым озвучиванием
            проекта выглядит так: вы передаёте нам сценарий и всю важную
            информацию о проекте, а взамен получаете полностью готовый файл со
            всеми правами на него.
          </CardDeckItem>
          <CardDeckItem variant="full-waves" textAlign="left">
            Мы берём на себя всю «головную боль». В кавычках — потому что работы
            действительно немало, но нам она нравится.
          </CardDeckItem>
          <CardDeckItem variant="waves" textAlign="left">
            Мы подбираем нужный вашему проекту голос, организовываем процесс
            записи, ведём проект и внимательно контролируем качество результата.
            Мы обрабатываем аудиофайлы и следим, чтобы всё звучало, как нужно.
            Кроме того, все юридические аспекты мы тоже берём на себя и передаём
            вам эксклюзивные права интеллектуальной собственности на весь
            готовый материал.
          </CardDeckItem>
        </CardDeck>
      </ServiceSection>
      <ServiceSection title="Отзывы клиентов и партнёров">
        <ReviewCarousel reviews={reviews} />
      </ServiceSection>
      <div className="contact-us-wrapper">
        <ContactUs>
          Расскажите нам о своём проекте, продукте или идее. Спросите совет или
          получите{" "}
          <a
            rel="nofollow noopener noreferrer"
            target="_blank"
            href={mailTo.mail.ru}
          >
            бесплатную консультацию
          </a>
          . Узнайте цены, уточните важный вопрос, да что угодно! Мы будем рады с
          вами познакомиться и помочь.
        </ContactUs>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query VoiceCastingRuPageQuery {
    seoPagesData: wpCustomPage(
      language: { code: { eq: RU } }
      seo: { pageSlug: { eq: "services/voice-casting" } }
    ) {
      ...seoCustomPageData
    }
    reviews: allWpClientReview(
      limit: 5
      filter: {
        meta: { viceOver: { viceOverVisibility: { eq: true } } }
        language: { code: { eq: RU } }
      }
    ) {
      ...serviceClientReviews
    }
    sidebarImage: file(relativePath: { eq: "services/voice-casting.jpg" }) {
      ...sidebarImage
    }
    sitePage {
      id
      path
    }
    site {
      siteMetadata {
        adminEmail
      }
    }
  }
`

export default VoiceCasting
