import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../../layouts/uk/sidebar"

import ServiceHero from "~components/ServiceHero"
import ContactUs from "~components/ContactUs"
import Sidebar from "~components/Sidebar"
import ServiceSection from "~components/ServiceSection"
import ServiceIconList from "~components/ServiceIconList"
import ServiceIcon from "~components/ServiceIcon"
import StyledAccordion from "~components/StyledAccordion"
import StyledAccordionItem from "~components/StyledAccordionItem"
import ReviewCarousel from "~components/ReviewCarousel/ReviewCarousel"
import CardDeck from "~components/CardDeck/CardDeck"
import CardDeckItem from "~components/CardDeckItem/CardDeckItem"
import WorkCards from "~components/WorkCards"
import PriceCard, { PriceCardList } from "~components/PriceCard"
import Button from "~components/Button"

import FemaleIcon from "~images/service-icons/female.svg"
import JingleIcon from "~images/service-icons/jingle.svg"
import AudioIcon from "~images/service-icons/audio.svg"
import PhoneIcon from "~images/service-icons/phone.svg"
import SoundIcon from "~images/service-icons/sound.svg"

import ABASIIcon from "~images/service-icons/audio-branding-app-sonic-Identity.svg"
import ABBIcon from "~images/service-icons/audio-branding-basic.svg"

import mailTo from "~utils/mailTo"
import { seoDefaultData } from "~utils/seo"

const AudioBranding = ({ data, location }) => {
  const reviews = data.reviews.nodes
  const sidebarImage = data.sidebarImage?.childImageSharp.fluid
  const works = data.works.nodes
  const { seo } = data.seoPagesData ?? { seo: seoDefaultData }

  return (
    <Layout
      Sidebar={
        <Sidebar
          title="Аудіобрендинг"
          text="У сучасному світі бренди повинні ставитися до звуку з тією ж ретельністю й дисципліною, що й до графічних стандартів та візуальної побудови бренду. Якщо ви застрягли у світі виключно візуального брендингу, то змагаєтеся ви з однією зав’язаною за спиною рукою."
          autor="Лоуренс Мінскі, Коллін Фейхі"
          image={sidebarImage}
        />
      }
      ServiceHero={
        <ServiceHero>
          <h3>Як для аудіофахівців, ми занадто цікавимося маркетингом.</h3>
          <p>
            Для нас він — сучасний комплексний вид мистецтва. Плюс, ми — фанати
            системного підходу, у якому завжди є місце креативності. Тож цілком
            очікувано, що нас зацікавила тема аудіобрендинга.
          </p>
          <p>
            Найчастіше бренди не використовують увесь потенціал звуку для
            підтримання своїх продуктів і ми хочемо це змінити. Адже правильний
            аудіо бренд допомагає встановити зв’язок з аудиторією, створити
            потрібну асоціацію, посилити запам’ятовуваність бренду і його
            ключового повідомлення, диференціюватися від конкурентів і навіть
            скорегувати сприйняття продукту або компанії.
          </p>
          <p>
            Зрештою, саме завдяки звуковій айдентиці людина миттєво впізнає ваш
            бренд, навіть якщо вона його ще не побачила.
          </p>
        </ServiceHero>
      }
      location={location}
      title={seo.title}
      disableSiteNameInTitle
      description={seo.description}
    >
      <ServiceSection title="Що ми робимо?">
        <p>Ми створюємо:</p>

        <ServiceIconList>
          <ServiceIcon title="Аудіо стратегії брендів" icon={FemaleIcon} />
          <ServiceIcon title="Аудіо логотипи та джингли" icon={JingleIcon} />
          <ServiceIcon title="Аудіорекламу та бренд-музику" icon={AudioIcon} />
          <ServiceIcon title="UI/UX звуковий дизайн" icon={PhoneIcon} />
          <ServiceIcon
            title="Iнші аудіальні точки контакту з клієнтом"
            icon={SoundIcon}
          />
        </ServiceIconList>

        <p>
          Кожен аудіо бренд — унікальний, як і сам бренд. У кожного бізнесу —
          свої потреби й немає універсальної формули та складу аудіо бренда.
          Тому для кожного проєкту ми створюємо персональну стратегію та набір
          елементів залежно від завдань, які необхідно виконати.
        </p>
      </ServiceSection>
      <ServiceSection title="Наші роботи">
        <WorkCards
          works={works}
          breakpoints={{
            default: 3,
            1100: 3,
            700: 3,
            500: 2,
          }}
        />
        <iframe
          title="Наші роботи"
          width="100%"
          height="450"
          scrolling="no"
          frameBorder="no"
          allow="autoplay"
          src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/758338767&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
        ></iframe>
        <div className="service-works-porfolio-button-container">
          <Button
            state={{
              service: "audio-branding",
            }}
            to="/portfolio?service=audio-branding"
            className="service-works-porfolio-button"
          >
            Портфолiо
          </Button>
        </div>
      </ServiceSection>
      <ServiceSection title="Як проходить процес?">
        <p>
          За останні кілька років ми розробили зрозумілу й ефективну схему
          співпраці. З одного боку, ми стежимо, щоби на кожному етапі у вас було
          чітке розуміння того, що відбувається, і повний контроль над ним. А з
          іншого — збираємо максимум інформації про ваш бренд, щоби розробити
          відповідну концепцію і створити аудіо, яке стане значущою частиною
          життя вашої аудиторії.
        </p>
        <StyledAccordion>
          <StyledAccordionItem title="Знайомство">
            <p>
              Ви заповнюєте бриф і надаєте список аудіоассетів, які нам потрібно
              буде створити. Ми призначаємо аудіо- або відеоконференцію в Google
              Meet або Zoom, під час якої знайомимося один з одним, обговорюємо
              проєкт і розуміємо, наскільки готові до співпраці. Для нас важливо
              збігатися з клієнтом у загальному баченні і принципах роботи.
            </p>
            <p>
              Якщо ви не можете ділитися інформацією про проєкт без NDA, то на
              початку спілкування ми підписуємо необхідні документи.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Юридичні формальності">
            <p>
              Ми уточнюємо обсяг проєкту, кількість і порядок платежів,
              підписуємо з вами договір і одержуємо аванс. Докладніше про
              фінансові та юридичні питання ви можете прочитати в нашому{" "}
              <Link to="/faq">FAQ</Link>.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Препродакшн">
            <p>
              За роки роботи ми неодноразово переконалися, що препродакшн багато
              в чому визначає успіх усього проєкту. На цьому етапі ми більш
              детально вивчаємо ваш бриф і ставимо додаткові питання, проводимо
              рісерч, складаємо технічне завдання, підбираємо{" "}
              <a
                target="_blank"
                rel="noopener noreferrer nofollow"
                href="/faq#audaliniimudbord"
              >
                аудіальні мудборди
              </a>{" "}
              і починаємо формувати{" "}
              <a
                target="_blank"
                rel="noopener noreferrer nofollow"
                href="/faq#audalinakontseptsya"
              >
                аудіальну концепцію
              </a>
              .
            </p>
            <p>
              Крім вашого бренду ми вивчаємо вашу нішу, її аудіальні коди та
              кліше, конкурентів, цільову аудиторію й на основі цієї інформації
              визначаємо завдання й майбутнє звучання аудіо бренду.
            </p>
            <p>
              Ми затверджуємо з вами готове технічне завдання, разом
              відслуховуємо аудіальні мудборди, за необхідності вносимо правки.
              Завдяки цьому ми визначаємо рамки, в яких ми маємо розробити
              аудіальну концепцію. Після цього ми одержуємо від вас другий
              платіж.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Продакшн">
            <p>
              На цьому етапі багато чого залежить від обсягу та специфіки
              проєкту, але загалом усе зводиться до таких дій:
            </p>
            <ul>
              <li>
                На підставі технічного завдання та аудіальних мудбордів ми
                складаємо список ідей щодо основних елементів{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                  href="/faq#audoDNK"
                >
                  аудіо-ДНК
                </a>{" "}
                і погоджуємо їх із вами.
              </li>
              <li>
                Ми розробляємо 2 варіанти демо аудіо-ДНК, кожен із яких
                по-своєму втілює цінності бренду. Відправляємо демо вам на
                фідбек, попередньо ознайомивши з дієвою методикою фідбеку. :)
              </li>
              <li>
                Затверджуємо з вами демо ДНК або складаємо список правок. На цій
                же стадії важливо провести тести на аудиторії, якщо у вас є така
                можливість. Якщо ж її немає — ми допоможемо організувати
                невелике внутрішнє тестування, щоби визначити, наскільки точно
                звучання демо співвідноситься з брендом.
              </li>
              <li>
                На основі коментарів ми разом обираємо більш релевантне бренду
                демо, допрацьовуємо аудіо-ДНК і відправляємо вам на фідбек.
              </li>
              <li>
                Затверджуємо з вами трек або вносимо нові правки — усього в нас
                передбачено 5 раундів безплатних правок.
              </li>
              <li>
                <p>
                  Після фіналізації аудіо-ДНК ми приступаємо до створення її{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                    href="/faq#audoadaptats"
                  >
                    адаптацій
                  </a>
                  , необхідних вашому бренду. Кожна адаптація проходить ті ж
                  стадії: демо, затвердження, тестування, фіналізація.
                </p>
                <p>
                  У великих і тривалих проєктах на цьому етапі може додатися ще
                  один проміжний платіж.
                </p>
              </li>
              <li>
                Коли всі аудіоассети готові, ми складаємо для вас аудіо
                стайлгайд, у якому зібрані всі основні правила використання
                елементів вашого аудіо бренду.
              </li>
            </ul>
          </StyledAccordionItem>
          <StyledAccordionItem title="Приймання проєкту">
            <p>
              Після всіх погоджень ми висилаємо вам кінцеві файли. Ви
              імплементуєте елементи аудіо бренду у потрібні точки контакту і
              надаєте нам до них доступ, щоби ми могли переконатися, що все
              звучить правильно. У разі, коли щось пішло не за планом, ми
              складаємо список виправлень і стежимо, щоби ви змогли все
              налаштувати.
            </p>
            <p>
              Коли все готово й добре звучить, ми з вами підписуємо акт
              прийому-передачі і ви відправляєте нам фінальну частину оплати.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Підтримання">
            <p>
              Ми любимо стежити за розвитком наших партнерів і клієнтів. Часто
              після приймання проєкту робота тільки починається й ми завжди
              готові продовжити співпрацю й додати нового звучання бренду. У
              рамках розробленої аудіальної концепції, звичайно. :)
            </p>
          </StyledAccordionItem>
        </StyledAccordion>
      </ServiceSection>

      <ServiceSection title="Вартість">
        <PriceCardList>
          <PriceCard
            icon={ABBIcon}
            title="Базовий аудіо бренд"
            list={[
              "глибинний брифінг",
              "аналіз ніші, конкурентів і цільової аудиторії",
              "складання технічного завдання",
              "розроблення аудіальної концепції",
              "створення аудіо-ДНК",
              "розроблення аудіо логотипу",
              "розроблення бренд-треку (у вигляді «конструктора»)",
              "складання аудіо стайлгайда",
            ]}
            price="$8500"
          />

          <PriceCard
            icon={ABASIIcon}
            title="Звукова айдентика застосунку"
            list={[
              "глибинний брифінг",
              "аналіз ніші, конкурентів і цільової аудиторії",
              "складання технічного завдання",
              "розроблення аудіальної концепції",
              "створення аудіо-ДНК",
              "розроблення звуків застосунку",
              "складання аудіо стайлгайда",
            ]}
            price="$2400 + $220/звук"
          />
        </PriceCardList>

        <p>Вартість всіх інших адаптацій аудіо-ДНК розраховується окремо.</p>
        <p>
          Етап рісерча і створення аудіо-ДНК — обов'язкова частина процесу
          створення звукової айдентики застосунку. Він не тільки визначає
          завдання і майбутнє звучання нотифікацій та in-app звуків, а й те, як
          будуть звучати всі потенційні точки контакту продукту з аудиторією.
          Саме аудіо-ДНК лежить в основі гнучкої аудіальної системи бренду, яка
          спрощує і здешевлює створення будь-якого аудіоконтенту, необхідного
          бренду.
        </p>
        <p>
          Тим не менш, ви можете заощадити на створенні аудіо-ДНК, якщо крім
          власне застосунку ви не плануєте комунікувати з аудиторією в інших
          каналах. На послузі аудіального рісерча (глибинний брифінг, аналіз
          ніші, конкурентів і цільової аудиторії) ви також можете заощадити, для
          цього вам потрібно надати нам детальну інформацію про продукт, включно
          з:
        </p>
        <ul>
          <li>позиціонуванням вашого продукту;</li>
          <li>його точками диференціації;</li>
          <li>списком конкурентів із прикладами їхнього аудіо;</li>
          <li>
            інформацією, як конкуренти використовують аудіо в маркетингових
            цілях;
          </li>
          <li>портретом(ами) вашої цільової аудиторії;</li>
          <li>потребами й уподобаннями вашої цільової аудиторії;</li>
          <li>кращими практиками й аудіальними кліше ніші.</li>
        </ul>
        <p>
          <a
            rel="nofollow noopener noreferrer"
            target="_blank"
            href={mailTo.audioBranding.uk}
          >
            Напишіть нам
          </a>{" "}
          і ми відповімо на будь-які ваші питання, і допоможемо з прорахунком
          проєкту.{" "}
          <span role="img" aria-label="руки">
            🙌
          </span>
        </p>
      </ServiceSection>
      <ServiceSection title="Наш підхід">
        <CardDeck>
          <CardDeckItem variant="rect" textAlign="left">
            Ми вважаємо своїм головним завданням створити унікальне звучання для
            вашого бренду та зробити його максимально інформативним. Вмістити
            всю суть у звук і правильно його спрямувати, щоби впливати на емоції
            вашого споживача так, як це потрібно вам.
          </CardDeckItem>
          <CardDeckItem variant="full-waves" textAlign="left">
            Ми продумуємо, де й коли ваш бренд буде звучати. Спочатку ми
            розробляємо аудіо-ДНК — головну музичну тему, у якій відображаються
            ідеологія і відмінні риси бренду. Вона визначає те, якими будуть усі
            інші елементи аудіо бренду: аудіо лого, бренд-треки, джингли,
            сповіщення застосунку тощо.
          </CardDeckItem>
          <CardDeckItem variant="waves" textAlign="left">
            Ми ретельно вивчаємо ваш бренд, пробираємося якнайглибше у ваш
            маркетинг-кит й ставимо багато запитань. :) А ще завжди раді, якщо у
            вас є можливість провести тести на цільовій аудиторії. Завдяки цьому
            ми можемо ще точніше створити потрібне вам звучання.
          </CardDeckItem>
        </CardDeck>
      </ServiceSection>
      <ServiceSection title="Відгуки клієнтів і партнерів">
        <ReviewCarousel reviews={reviews} />
      </ServiceSection>
      <div className="contact-us-wrapper">
        <ContactUs>
          Розкажіть нам про свій проєкт, продукт або ідею. Запитайте пораду або
          отримайте{" "}
          <a
            rel="nofollow noopener noreferrer"
            target="_blank"
            href={mailTo.mail.uk}
          >
            безплатну консультацію
          </a>
          . Дізнайтеся вартість, уточніть важливе питання, та що завгодно! Ми
          будемо раді з вами познайомитися й допомогти.
        </ContactUs>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query AudioBrandingUkPageQuery {
    seoPagesData: wpCustomPage(
      language: { code: { eq: UK } }
      seo: { pageSlug: { eq: "services/audio-branding" } }
    ) {
      ...seoCustomPageData
    }
    reviews: allWpClientReview(
      limit: 5
      filter: {
        meta: { audiobrending: { audiobrendingVisibility: { eq: true } } }
        language: { code: { eq: UK } }
      }
    ) {
      ...serviceClientReviews
    }
    works: allWpWork(
      filter: {
        language: { code: { eq: UK } }
        meta: { audiobrending: { audiobrendingVisibility: { eq: true } } }
      }
      sort: { fields: meta___audiobrending___audiobrendingOrder, order: DESC }
    ) {
      ...serviceWorks
    }
    sidebarImage: file(relativePath: { eq: "services/audio-branding.jpg" }) {
      ...sidebarImage
    }
    sitePage {
      id
      path
    }
  }
`

export default AudioBranding
