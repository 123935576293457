import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../../layouts/uk/sidebar"

import ServiceHero from "~components/ServiceHero"
import ContactUs from "~components/ContactUs"
import Sidebar from "~components/Sidebar"
import ServiceSection from "~components/ServiceSection"
import ServiceIconList from "~components/ServiceIconList"
import ServiceIcon from "~components/ServiceIcon"
import StyledAccordion from "~components/StyledAccordion"
import StyledAccordionItem from "~components/StyledAccordionItem"
import ReviewCarousel from "~components/ReviewCarousel/ReviewCarousel"
import CardDeck from "~components/CardDeck/CardDeck"
import CardDeckItem from "~components/CardDeckItem/CardDeckItem"
import LazyIframe from "~components/LazyIframe"
import PriceCard, { PriceCardList } from "~components/PriceCard"

import MaleIcon from "~images/service-icons/male.svg"
import LaptopIcon from "~images/service-icons/laptop.svg"
import FileIcon from "~images/service-icons/file.svg"

import VCFAIcon from "~images/service-icons/voice-casting-foreign-actors.svg"
import VCLAIcon from "~images/service-icons/voice-casting-local-actors.svg"

import mailTo from "~utils/mailTo"
import { seoDefaultData } from "~utils/seo"

const VoiceCasting = ({ data, location }) => {
  const reviews = data.reviews.nodes
  const sidebarImage = data.sidebarImage.childImageSharp.fluid
  const contactEmail = data.site.siteMetadata.adminEmail
  const { seo } = data.seoPagesData ?? { seo: seoDefaultData }

  return (
    <Layout
      Sidebar={
        <Sidebar
          title="Голосове озвучення"
          text="Очима не побачиш те, що може намалювати нам мова."
          autor="Китайське прислів’я"
          image={sidebarImage}
        />
      }
      ServiceHero={
        <ServiceHero>
          <h3>
            Нам подобається думати про людський голос як про найдосконаліший
            музичний інструмент.
          </h3>
          <p>
            Ми не знаємо, що ще здатне так точно розкрити посил автора, передати
            весь спектр емоцій і невловимі нюанси настрою. І якщо вокал — це
            один зі значущих елементів музики, то голосове озвучення — це
            самостійний вид мистецтва.
          </p>
          <p>
            Правильне голосове озвучення або озвучування — це не просто начитка
            потрібного тексту добре поставленим голосом. Це здебільшого про
            управління увагою слухача, розкриття персонажів і створення
            атмосфери. Це те, що змушує людину слухати і вірити почутому.
          </p>
        </ServiceHero>
      }
      location={location}
      title={seo.title}
      disableSiteNameInTitle
      description={seo.description}
    >
      <ServiceSection title="Що ми робимо?">
        <p>
          Якщо говорити коротко — ми продюсуємо голосове озвучення, а конкретно
          займаємося:
        </p>
        <ServiceIconList align="space-evenly">
          <ServiceIcon
            title="Кастингом акторів голосового озвучення"
            icon={MaleIcon}
            style={{ maxWidth: 200 }}
          />
          <ServiceIcon
            title="Організацією процесу запису і веденням проєкту"
            icon={LaptopIcon}
            style={{ maxWidth: 200 }}
          />
          <ServiceIcon
            title="Обробкою аудіо файлів"
            icon={FileIcon}
            style={{ maxWidth: 200 }}
          />
        </ServiceIconList>
        <p>
          А ще ми співпрацюємо з професійними вокалістами, щоби додати необхідну
          людяність та глибину{" "}
          <Link to="/services/audio-branding">аудіо брендам</Link> і{" "}
          <Link to="/services/music-composing">музиці</Link>.
        </p>
      </ServiceSection>
      <ServiceSection title="База голосів">
        <p>
          За роки роботи ми сформували базу акторів голосового озвучення та
          вокалістів. Кожен із них — носій найрізноманітніших мов і акцентів,
          тембрів і інтонацій. У цьому ви можете переконатися самі, плейлисти із
          прикладами голосів акторів і вокалістів ви знайдете нижче.
        </p>
        <h3>Актори озвучення</h3>
        <LazyIframe
          title="Актори озвучення"
          height="450"
          scrolling="no"
          frameBorder="no"
          allow="autoplay"
          src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/303617614%3Fsecret_token%3Ds-jgwUQD8Wr0W&color=%23f23b0d&auto_play=false&hide_related=false&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"
        />
        <p>
          Завдяки тому, що всі актори з нашої бази — носії мов, послуга{" "}
          <strong>пруфрідінга</strong> вже включена у вартість робіт.
        </p>
        <h3>Вокалісти</h3>
        <LazyIframe
          title="Вокалісти"
          height="450"
          scrolling="no"
          frameBorder="no"
          allow="autoplay"
          src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1259357083%3Fsecret_token%3Ds-vWRXTht4Qep&color=%23f23b0d&auto_play=false&hide_related=false&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"
        />
      </ServiceSection>
      <ServiceSection title="Як проходить процес?">
        <p>
          За останні кілька років ми розробили зрозумілу й ефективну схему
          співпраці. З одного боку, ми стежимо, щоби на кожному етапі у вас було
          чітке розуміння того, що відбувається, і повний контроль над ним. А з
          іншого — збираємо максимум інформації про ваш проєкт, щоби забезпечити
          його найкращими голосами.
        </p>
        <StyledAccordion>
          <StyledAccordionItem title="Знайомство">
            <p>
              Ви заповнюєте бриф і надаєте сценарій, який нам потрібно буде
              озвучити. Ми призначаємо аудіо- або відеоконференцію в Google Meet
              або Zoom, під час якої знайомимося один з одним, обговорюємо
              проєкт і розуміємо, наскільки готові до співпраці. Для нас важливо
              збігатися з клієнтом у загальному баченні і принципах роботи.
            </p>
            <p>
              Якщо ви не можете ділитися інформацією про проєкт без NDA, то на
              початку спілкування ми підписуємо необхідні документи.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Препродакшн">
            <p>
              На цьому етапі ми детально вивчаємо технічне завдання, сценарій та
              описи персонажів, підбираємо відповідних виконавців і демонструємо
              їх вам. За необхідності — записуємо пробні фрази, щоби вам було
              простіше визначитися з актором.
            </p>
            <p>
              Паралельно разом із юристами ми готуємо договір співпраці.
              Обговорюємо й погоджуємо все з вами й переходимо до наступного
              етапу.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Юридичні формальності">
            <p>
              Ми з вами підписуємо договір, ви висилаєте повну передоплату за
              проєкт, а ми зі свого боку підписуємо договір з актором і
              передаємо йому сценарій.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Продакшн">
            <p>
              Актор озвучення відправляє нам готовий запис, ми перевіряємо його
              на відповідність вимогам і, якщо все добре, надсилаємо вам. Якщо
              вам здасться, що виконавець не досить коректно передав якісь
              інтонації або загальний посил, ви зможете внести до двох
              безплатних правок на кожен рядок сценарію.
            </p>
            <p>
              Після того, як вас влаштує робота актора, ми займемося стандартною
              обробкою аудіофайлу. На цьому ж етапі ми можемо додатково обробити
              записаний голос, додати потрібних ефектів.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Приймання проєкту">
            <p>
              Після всіх погоджень ми висилаємо вам кінцеві файли. Цей момент —
              останній шанс скористатися безплатними правками.
            </p>
            <p>
              Ви імплементуєте записані голоси у свій проєкт і надаєте нам до
              нього доступ, щоби ми могли переконатися, що все звучить
              правильно. У разі, коли щось пішло не за планом, ми складаємо
              список виправлень і стежимо, щоби ви змогли все налаштувати.{" "}
            </p>
            <p>
              {" "}
              Коли все готово й добре звучить, ми з вами підписуємо акт
              приймання-передачі.{" "}
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Підтримання">
            <p>
              Ми любимо стежити за розвитком наших партнерів і клієнтів. Часто
              після приймання проєкту робота тільки починається й ми завжди
              готові продовжити співпрацю.
            </p>
          </StyledAccordionItem>
        </StyledAccordion>
      </ServiceSection>

      <ServiceSection title="Вартість">
        <p style={{ marginBottom: 60 }}>
          Вартість проєкту залежить від вибору актора озвучення, базова обробка
          голосу (чистка, еквалізація, компресія, лімітування) включена у
          вартість, специфічна обробка (ефект радіо, ехо, голос робота тощо)
          обговорюється окремо.
        </p>
        <PriceCardList>
          <PriceCard
            icon={VCLAIcon}
            title="Локальні актори озвучення"
            subTitle="Доступні мови:"
            list={["українська", "російська", "англійська"]}
            price="$400/год"
          />
          <PriceCard
            icon={VCFAIcon}
            title="Іноземні актори озвучення"
            subTitle="Доступні мови:"
            list={[
              "англійська",
              "німецька",
              "французька",
              "іспанська",
              "китайська",
            ]}
            price="$600/год"
          />
        </PriceCardList>
        <p>
          Година роботи включає запис актора і базову обробку голосу. В
          середньому за одну годину роботи можна озвучити 800-1000 слів
          сценарію, але все залежить від специфіки проєкту.
        </p>
        <p>Мінімальна сума замовлення складає вартість однієї години роботи.</p>
        <p>
          Якщо в списку доступних мов ви не знайшли потрібну вам — обов’язково{" "}
          <a
            rel="nofollow noopener noreferrer"
            target="_blank"
            href={`mailto:${contactEmail}`}
          >
            напишіть нам
          </a>{" "}
          і ми підберемо потрібного актора.
        </p>
        <p>
          Вартість роботи вокалістів безпосередньо залежить від обсягу та
          складності проєкту й розраховується індивідуально.
        </p>
      </ServiceSection>
      <ServiceSection title="Наш підхід">
        <CardDeck>
          <CardDeckItem variant="rect" textAlign="left">
            У нашому розумінні ідеальна робота над голосовим озвученням проєкту
            виглядає так: ви передаєте нам сценарій і всю важливу інформацію
            щодо проєкту, а натомість отримуєте повністю готовий файл з усіма
            правами на нього.
          </CardDeckItem>
          <CardDeckItem variant="full-waves" textAlign="left">
            Ми беремо на себе весь «головний біль». У лапках — тому що роботи
            дійсно чимало, але нам вона подобається.
          </CardDeckItem>
          <CardDeckItem variant="waves" textAlign="left">
            Ми підбираємо потрібний вашому проєкту голос, організовуємо процес,
            ведемо проєкт і уважно контролюємо якість результату. Ми обробляємо
            аудіофайли і стежимо, щоб усе звучало, як потрібно. Крім того, усі
            юридичні аспекти ми теж беремо на себе й передаємо вам ексклюзивні
            права інтелектуальної власності на весь готовий матеріал.
          </CardDeckItem>
        </CardDeck>
      </ServiceSection>
      <ServiceSection title="Відгуки клієнтів і партнерів">
        <ReviewCarousel reviews={reviews} />
      </ServiceSection>
      <div className="contact-us-wrapper">
        <ContactUs>
          Розкажіть нам про свій проєкт, продукт або ідею. Запитайте пораду або
          отримайте{" "}
          <a
            rel="nofollow noopener noreferrer"
            target="_blank"
            href={mailTo.mail.uk}
          >
            безплатну консультацію
          </a>
          . Дізнайтеся вартість, уточніть важливе питання, та що завгодно! Ми
          будемо раді з вами познайомитися й допомогти.
        </ContactUs>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query VoiceCastingUkPageQuery {
    seoPagesData: wpCustomPage(
      language: { code: { eq: UK } }
      seo: { pageSlug: { eq: "services/voice-casting" } }
    ) {
      ...seoCustomPageData
    }
    reviews: allWpClientReview(
      limit: 5
      filter: {
        meta: { viceOver: { viceOverVisibility: { eq: true } } }
        language: { code: { eq: UK } }
      }
    ) {
      ...serviceClientReviews
    }
    sidebarImage: file(relativePath: { eq: "services/voice-casting.jpg" }) {
      ...sidebarImage
    }
    sitePage {
      id
      path
    }
    site {
      siteMetadata {
        adminEmail
      }
    }
  }
`

export default VoiceCasting
