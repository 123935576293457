import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../../layouts/uk/sidebar"
import ServiceHero from "../../components/ServiceHero"
import ContactUs from "../../components/ContactUs"
import Sidebar from "../../components/Sidebar"
import ServiceSection from "../../components/ServiceSection"
import ServiceIconList from "../../components/ServiceIconList"
import ServiceIcon from "../../components/ServiceIcon"
import StyledAccordion from "../../components/StyledAccordion"
import StyledAccordionItem from "../../components/StyledAccordionItem"
import ReviewCarousel from "../../components/ReviewCarousel/ReviewCarousel"
import CardDeck from "../../components/CardDeck/CardDeck"
import CardDeckItem from "../../components/CardDeckItem/CardDeckItem"
import WorkCards from "../../components/WorkCards"

import PlayerIcon from "../../images/service-icons/player.svg"
import GamesIcon from "../../images/service-icons/games.svg"
import RoomIcon from "../../images/service-icons/room.svg"

import mailTo from "../../utils/mailTo"
import { seoDefaultData } from "../../utils/seo"
import Button from "~components/Button"
import InlineButton from "~components/InlineButton"
import ModalContactForm from "~components/ModalContactForm"

const SoundDesign = ({ data, location }) => {
  const [showForm, setShowForm] = useState(false)

  const reviews = data.reviews.nodes
  const sidebarImage = data.sidebarImage.childImageSharp.fluid
  const works = data.works.nodes
  const { seo } = data.seoPagesData ?? { seo: seoDefaultData }

  return (
    <Layout
      Sidebar={
        <Sidebar
          title="Звуковий дизайн"
          text="Під час створення ігор, як і під час зйомок кіно, звук має дуже велике значення. 60 або навіть 70 % відчуттів створюється саме завдяки звуку. Водночас звук — це не тільки музичний супровід, а і звукові ефекти. Їхня наявність або відсутність дуже сильно визначає атмосферу гри."
          autor="Хідео Кодзіма"
          image={sidebarImage}
        />
      }
      ServiceHero={
        <ServiceHero>
          <h3>
            Якщо музика — це мистецтво, то звук — насамперед спосіб передачі
            інформації.
          </h3>
          <p>
            Притому, один із найдавніших. Але це не означає, що музика — тільки
            емоційна, а звук — лише інформативний. Точно як і музика, секундний
            звук може безпосередньо впливати на емоції людини.
          </p>
          <p>
            Хочемо ми цього чи ні, але тривожний звук змусить нас насторожитися.
            Саме за подібні речі ми й любимо звуковий дизайн. А ще за те, як він
            удосконалює будь-яку картинку та допомагає глядачеві повірити в те,
            що відбувається. Магія!
          </p>
        </ServiceHero>
      }
      location={location}
      title={seo.title}
      disableSiteNameInTitle
      description={seo.description}
    >
      <ServiceSection title="Що ми робимо?">
        <p>Ми створюємо:</p>
        <ServiceIconList>
          <ServiceIcon
            title="Звукові ефекти, ембієнти та джингли для відеоігор"
            icon={GamesIcon}
            style={{ maxWidth: 260 }}
          />
          <ServiceIcon
            title="Звукове оформлення відео"
            icon={PlayerIcon}
            style={{ maxWidth: 260 }}
          />
          <ServiceIcon
            title="Звукове оформлення простору (квест-кімнати, інсталяції тощо)"
            icon={RoomIcon}
            style={{ maxWidth: 260 }}
          />
        </ServiceIconList>

        <p>
          Кожен проєкт ми починаємо з вивчення його цільової аудиторії та
          контексту, у якому він звучатиме. Завдяки цьому ми визначаємо
          завдання, які будуть виконувати звуки. Такий підхід дає нам змогу
          створити не просто набір окремих ассетів, а ефективну гармонійну
          систему.
        </p>
        <p>
          Ми заздалегідь обговорюємо нюанси майбутньої імплементації звуків,
          продумуємо, де і як вони будуть відтворюватися, і враховуємо цю
          інформацію під час їхнього створення.
        </p>
        <p>
          До речі, звуковий дизайн для застосунків ми теж робимо, але підходимо
          до його розробки ще більш ґрунтовно. У цьому випадку мова йде
          ймовірніше про аудіальну айдентику — прочитати про це докладніше ви
          можете на{" "}
          <Link to="/services/audio-branding">сторінці аудіобрендинга</Link>.{" "}
        </p>
      </ServiceSection>
      <ServiceSection title="Наші роботи">
        <WorkCards
          works={works}
          breakpoints={{
            default: 3,
            1100: 3,
            700: 3,
            500: 2,
          }}
        />
        <div className="service-works-porfolio-button-container">
          <Button
            state={{
              service: "sound-design",
            }}
            to="/portfolio?service=sound-design"
            className="service-works-porfolio-button"
          >
            Портфолiо
          </Button>
        </div>
      </ServiceSection>
      <ServiceSection title="Як проходить процес?">
        <p>
          За останні кілька років ми розробили зрозумілу й ефективну схему
          співпраці. З одного боку, ми стежимо, щоби на кожному етапі у вас було
          чітке розуміння того, що відбувається, і повний контроль над ним. А з
          іншого — збираємо максимум інформації про ваш продукт, щоби розробити
          відповідну концепцію і створити аудіо, яке стане значущою частиною
          життя вашої аудиторії.
        </p>
        <StyledAccordion>
          <StyledAccordionItem title="Знайомство">
            <p>
              Ви заповнюєте бриф і надаєте список звукових ассетів, які нам
              потрібно буде створити. Ми призначаємо аудіо- або відеоконференцію
              в Google Meet або Zoom, під час якої знайомимося один з одним,
              обговорюємо проєкт і розуміємо, наскільки готові до співпраці. Для
              нас важливо збігатися з клієнтом у загальному баченні і принципах
              роботи.
            </p>
            <p>
              Якщо ви не можете ділитися інформацією про проєкт без NDA, то на
              початку спілкування ми підписуємо необхідні документи.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Юридичні формальності">
            <p>
              Ми уточнюємо обсяг проєкту, кількість і порядок платежів,
              підписуємо з вами договір і одержуємо аванс. Докладніше про
              фінансові та юридичні питання ви можете прочитати в нашому{" "}
              <Link to="/faq">FAQ</Link>.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Препродакшн">
            <p>
              За роки роботи ми неодноразово переконалися, що препродакшн багато
              в чому визначає успіх усього проєкту. На цьому етапі ми більш
              детально вивчаємо ваш бриф і ставимо додаткові питання, проводимо
              рісерч, складаємо технічне завдання і створюємо{" "}
              <a
                target="_blank"
                rel="noopener noreferrer nofollow"
                href="/faq#audalinakontseptsya"
              >
                аудіальну концепцію
              </a>
              .
            </p>
            <p>
              Крім вашого проєкту ми вивчаємо вашу нішу, конкурентів, цільову
              аудиторію й на основі цієї інформації визначаємо завдання й
              майбутнє звучання музики. Допомагають нам у цьому{" "}
              <InlineButton onClick={() => setShowForm(true)}>
                перевірений роками темплейт ТЗ
              </InlineButton>{" "}
              і{" "}
              <a
                target="_blank"
                rel="noopener noreferrer nofollow"
                href="/faq#audaliniimudbord"
              >
                аудіальні мудборди
              </a>
              .{" "}
            </p>
            <p>
              Ми затверджуємо з вами готове технічне завдання й документ з
              аудіальною концепцією, за необхідності вносимо правки. Після цього
              ми одержуємо від вас другий платіж.
            </p>
          </StyledAccordionItem>
          <ModalContactForm
            title="Темплейт технічного завдання"
            subTitle="Ми впевнені, що правильно складене ТЗ підвищує шанси на успіх проєкту. Тому з радістю ділимося тим, що працює для нас!"
            open={showForm}
            onClose={() => setShowForm(false)}
          />
          <StyledAccordionItem title="Продакшн">
            <p>
              На цьому етапі багато чого залежить від обсягу та специфіки
              проєкту, але загалом усе зводиться до таких дій:
            </p>
            <ul>
              <li>
                Ми створюємо 3–5 тестових звуків і відправляємо вам на фідбек,
                попередньо ознайомивши з дієвою методикою фідбеку. :)
              </li>
              <li>Затверджуємо звучання з вами або складаємо список правок.</li>
              <li>
                На основі тестових звуків і коментарів ми допрацьовуємо
                наступний пак ассетів, відправляємо вам на фідбек.
              </li>
              <li>
                Затверджуємо з вами звуки або вносимо нові правки — усього в нас
                передбачено 5 раундів безплатних правок.
              </li>
            </ul>
            <p>
              Цикл повторюється залежно від кількості паків звуків. У великих і
              тривалих проєктах на цьому етапі може додатися ще один проміжний
              платіж.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Приймання проєкту">
            <p>
              Після всіх погоджень ми висилаємо вам кінцеві файли. Цей момент —
              останній шанс скористатися безплатними правками.
            </p>
            <p>
              Ви імплементуєте звуки у свій проєкт і надаєте нам до нього
              доступ, щоби ми могли переконатися, що все звучить правильно. У
              разі, коли щось пішло не за планом, ми складаємо список виправлень
              і стежимо, щоби ви змогли все налаштувати.
            </p>
            <p>
              Коли все готово й добре звучить, ми з вами підписуємо акт
              прийому-передачі і ви відправляєте нам фінальну частину оплати.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Підтримання">
            <p>
              Ми любимо стежити за розвитком наших партнерів і клієнтів. Часто
              після приймання проєкту робота тільки починається й ми завжди
              готові продовжити співпрацю й додати нового звучання продукту. У
              рамках розробленої аудіальної концепції, звичайно. :)
            </p>
          </StyledAccordionItem>
        </StyledAccordion>
      </ServiceSection>

      <ServiceSection title="Вартість">
        <p>Звуковий ефект (sfx) — $40;</p>
        <p>Джингл (коротка музична фраза) — $60;</p>
        <p>Ембієнт (комплексний звук оточення) — $80.</p>
        <p>
          Створення аудіо для відеороликів (музика + звуковий дизайн) —
          $1500/хв.
        </p>
        <p>У вартість усіх звукових ассетів входить:</p>
        <ul>
          <li>5 раундів безплатних правок;</li>
          <li>виключні майнові права інтелектуальної власності;</li>
          <li>допомога в імплементації та тестуванні.</li>
        </ul>
        <p>
          Мінімальна сума замовлення — $400.{" "}
          <a href={mailTo.soundDesign.uk}>Напишіть нам</a> і ми відповімо на
          будь-які ваші питання та допоможемо з прорахунком проєкту.{" "}
          <span role="img" aria-label="руки">
            🙌
          </span>
        </p>
      </ServiceSection>
      <ServiceSection title="Наш підхід">
        <CardDeck>
          <CardDeckItem variant="rect" textAlign="left">
            Для нас важливо змусити кожен елемент звучати так, щоб у слухача не
            виникало сумнівів, що це його «рідний» звук. Кожен ефект ми
            створюємо з думкою про почуття, які він має викликати в людини.
          </CardDeckItem>
          <CardDeckItem variant="full-waves" textAlign="left">
            Чи буде звук напружувати? Чи змусить відчути полегшення? Або
            попередить про щось? Ми продумуємо кожну деталь. Особливо те, як
            будуть звучати всі звуки разом. Як вони будуть взаємодіяти один з
            одним і загальним фоном — ембієнтом або музикою.
          </CardDeckItem>
          <CardDeckItem variant="waves" textAlign="left">
            Ми разом із вами хочемо бути впевненими у фінальному звучанні
            проєкту. Тому ми уважно відслуховуємо, як працює звуковий дизайн у
            контексті продукту й допомагаємо нашим клієнтам налаштувати рівні
            гучності, кількість одночасних відтворень, рандомізацію,
            варіативність ассетів і інші важливі моменти.
          </CardDeckItem>
        </CardDeck>
      </ServiceSection>
      <ServiceSection title="Відгуки клієнтів і партнерів">
        <ReviewCarousel reviews={reviews} />
      </ServiceSection>
      <div className="contact-us-wrapper">
        <ContactUs>
          Розкажіть нам про свій проєкт, продукт або ідею. Запитайте пораду або
          отримайте{" "}
          <a
            rel="nofollow noopener noreferrer"
            target="_blank"
            href={mailTo.mail.uk}
          >
            безплатну консультацію
          </a>
          . Дізнайтеся вартість, уточніть важливе питання, та що завгодно! Ми
          будемо раді з вами познайомитися й допомогти.
        </ContactUs>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query SoundDesignUKPageQuery {
    seoPagesData: wpCustomPage(
      language: { code: { eq: UK } }
      seo: { pageSlug: { eq: "services/sound-design" } }
    ) {
      ...seoCustomPageData
    }
    reviews: allWpClientReview(
      limit: 5
      filter: {
        meta: { soundDesign: { soundDesignVisibility: { eq: true } } }
        language: { code: { eq: UK } }
      }
    ) {
      ...serviceClientReviews
    }
    works: allWpWork(
      filter: {
        language: { code: { eq: UK } }
        meta: { soundDesign: { soundDesignVisibility: { eq: true } } }
      }
      sort: { fields: meta___soundDesign___soundDesignOrder, order: DESC }
    ) {
      ...serviceWorks
    }
    sidebarImage: file(relativePath: { eq: "services/sound-design.jpg" }) {
      ...sidebarImage
    }
    sitePage {
      id
      path
    }
  }
`

export default SoundDesign
